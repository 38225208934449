export const ROUTER_LINKS = Object.freeze({
  HOME: '/main',
  REGISTRATION: '/registration',
  LOGIN: '/login',
  VERIFICATION: '/verification',
  RESTORE_PASSWORD: '/restore-password',
  PROFILE: '/profile',
  ADMIN_MAIN: '/admin-main',
  ADMIN_DASHBOARD: 'admin-dashboard',
  USERS: 'users',
  USER_CARD: 'user-card',
  MONITORING: 'monitoring',
  PARTNERS: 'partners',
  PARTNER_CARD: 'partner-card',
  TERMINALS: 'terminals',
  TERMINAL_CARD: 'terminal-card',
  DEVICES: 'devices',
  DEVICE_CARD: 'device-card',
  SERVICE_POINTS: 'service-points',
  SERVICE_POINT_CARD: 'service-point-card',
  STAFF: 'staff',
  STAFF_CARD: 'staff-card',
  DASHBOARD_CONSTRUCTOR_LIST: 'dashboard-constructor-list',
  COMPANY_DASHBOARD: 'company-dashboard',
  DASHBOARD: 'dashboard',
  DASHBOARD_MAIN: '/2',
  DASHBOARD_FINANCE: '/3',
  DASHBOARD_TECHNICAL: '/4',
  DASHBOARD_MARKETING: '/5',
  DASHBOARD_SUPPORT: '/9',
  COMPANIES_LIST: 'companies-list',
  COMPANIES_CARD: 'company-card',
  SETTINGS: 'settings',
  PAYMENTS: 'payment-list',
  PAYMENT_CARD: 'payment-card',
  FINANCIAL_STATEMENTS: 'financial-statements',
  FINANCIAL_ENCASHMENTS: 'encashments',
  FINANCIAL_ENCASHMENT: 'encashment',
  CLIENTS: 'clients',
  CLIENT_CARD: 'client-card',
  PROMOTIONS: 'promotions',
  PROMOTION_CARD: 'promotion-card',
  PROMOTION_EDIT: 'promotion-edit',
  GLOBAL_PROMOTION_CARD: 'global-promotion-card',
  INFO_CONSTRUCTOR_CATEGORIES: 'info-constructor-categories',
  INFO_CONSTRUCTOR_ARTICLES_LIST: 'info-constructor-articles',
  INFO_CONSTRUCT_ARTICLE: 'info-construct-article',
  INFO_ARTICLE: 'info-article',
  INFO_CONSTRUCTOR_EVENTS_GROUP_LIST: 'info-constructor-events-group-list',
  INFO_CONSTRUCTOR_EVENTS_LIST: 'info-constructor-events-list',
  INFO_CONSTRUCTOR_EVENT_CARD: 'info-event-card',
  INFO_CONSTRUCT_EVENT: 'info-construct-event',
  DEVICE_EVENTS: 'device-events',
  DEVICE_EVENTS_MAINTENANCE: 'device-events-maintenance',
  PACKAGES: 'packages',
  FIRMWARES: 'firmwares',
  FIRMWARE_CARD: 'firmware-card',
  PUSH_NOTIFICATIONS_MAIN: 'push-notifications-main',
  PUSH_NOTIFICATIONS_CARD: 'push-notifications-card',
  PUSH_NOTIFICATIONS_SETTINGS: 'push-notifications-settings',
  PUSH_NOTIFICATIONS_DELIVERY: 'push-notifications-delivery',
  ERRORS: {
    ERROR_401: '/401',
    ERROR_403: '/403',
    ERROR_404: '/404',
    ERROR_500: '/500',
    ERROR_COMPANY_LOCKED: '/company-locked',
  },
})
