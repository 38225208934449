<template>
  <v-menu bottom left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-avatar v-if="avatar" size="32">
          <img :src="`${baseURL}/${avatar}`" alt="avatar" />
        </v-avatar>
        <v-icon v-else>mdi-account-circle-outline</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item :to="ROUTER_LINKS.PROFILE">
        <v-list-item-title>Профіль</v-list-item-title>
      </v-list-item>

      <v-list-item @click="userLogout">
        <v-list-item-title>Вийти</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { ROUTER_LINKS } from '@/const/router-links.enum'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'ProfileMenu',

  props: {
    avatar: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    ROUTER_LINKS: ROUTER_LINKS,
  }),

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapMutations('init', ['SET_STATE_IS_INITIALIZED']),
    ...mapMutations('companies', ['SET_CURRENT_COMPANY', 'SET_COMPANIES_FOR_SEARCH', 'SET_COMPANIES_FOR_SEARCH_META']),
    ...mapMutations('profile', ['SET_CURRENT_USER', 'SET_CURRENT_USER_ROLES']),

    async userLogout() {
      await this.logout()
      this.SET_STATE_IS_INITIALIZED(false)
      this.SET_CURRENT_USER(null)
      this.SET_CURRENT_USER_ROLES(null)
      this.SET_CURRENT_COMPANY(null)
      this.SET_COMPANIES_FOR_SEARCH([])
      this.SET_COMPANIES_FOR_SEARCH_META({})
      this.clearStorage()
      await this.$router.replace(this.ROUTER_LINKS.LOGIN)
    },
    clearStorage() {
      window.localStorage.clear()
    },
  },
}
</script>

<style scoped></style>
