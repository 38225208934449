var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.promotionsData,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalCount,"footer-props":{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"border-right":"1px solid rgba(0, 0, 0, 0.12)"},attrs:{"colspan":"10"}},[_vm._v("Акція")]),_c('th',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v("Місце")])])])]},proxy:true},{key:"item.id",fn:function({ item }){return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
          name: 'PromotionCard',
          params: { promotionId: item.id },
        }}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.start_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("getShortDate")(item.start_at))+" "),_c('sup',[_vm._v(_vm._s(_vm._f("getShortTime")(item.start_at)))])]}},{key:"item.expired_at",fn:function({ item }){return [_c('span',{class:{ 'red--text text--lighten-1': _vm.isDateExpired(item.expired_at) }},[_vm._v(" "+_vm._s(_vm._f("getShortDate")(item.expired_at))+" "),_c('sup',[_vm._v(_vm._s(_vm._f("getShortTime")(item.expired_at)))])])]}},{key:"item.type",fn:function({ item }){return [_vm._v(_vm._s(_vm.getPromType(item.type)))]}},{key:"item.is_available_once",fn:function({ item }){return [_vm._v(_vm._s(item.is_available_once ? 'Разова' : 'Постійна')+" "+_vm._s(item.company ? '' : 'глобальна')+" ")]}},{key:"item.is_active",fn:function({ item }){return [_c('CircleIndicator',{attrs:{"is-on":item.is_active}})]}},{key:"item.users",fn:function({ item }){return _vm._l((item.users),function(user){return _c('div',{key:user.id},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
            name: 'UserCard',
            params: { id: _vm.currentCompanyId, userId: user.id },
          }}},[_vm._v(" "+_vm._s(user.first_name)+" "+_vm._s(user.last_name)+" ")])],1)})}},{key:"item.value_type",fn:function({ item }){return [_vm._v(_vm._s(item.value_type === 1 ? 'грн.' : '%'))]}},{key:"item.amount",fn:function({ item }){return [(!!item.conditions.length)?_c('div',[_vm._v(_vm._s(_vm.getConditionsMinMax(item.conditions)))]):_c('div',[_vm._v(_vm._s(item.amount))])]}},{key:"item.devices",fn:function({ item }){return _vm._l((item.devices),function(device,index){return _c('div',{key:index},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'DeviceCard', params: { deviceId: device.id } }}},[_c('span',[_vm._v(_vm._s(_vm.getDeviceTitle(device.type))+_vm._s(device.id))])])],1)})}},{key:"item.service_points",fn:function({ item }){return _vm._l((item.service_points),function(point){return _c('div',{key:point.id},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
            name: 'ServicePointCard',
            params: { id: _vm.currentCompanyId, servicePointId: point.id },
          }}},[_vm._v(" "+_vm._s(point.name)+" ")])],1)})}},{key:"item.actions",fn:function({ item }){return [_c('div',{staticClass:"flex flex-row justify-space-between"},[_c('v-tooltip',{attrs:{"open-on-focus":false,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline")])]}}],null,true)},[_c('span',[_vm._v("Редагувати")])]),(_vm.active)?_c('v-tooltip',{attrs:{"open-on-focus":false,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deletePromotion(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Перемістити в архів")])]):_vm._e(),_c('v-tooltip',{attrs:{"open-on-focus":false,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.navigateToPromotionCard(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-outline")])]}}],null,true)},[_c('span',[_vm._v("Переглянути")])]),_vm._t("append")],2)]}}],null,true)}),_c('AskDialog',{attrs:{"message":_vm.deleteMessages.msg,"sub-message":_vm.deleteMessages.subMsg,"is-opened":_vm.dialogDelete},on:{"yes":_vm.deletePromotionConfirm,"no":_vm.closeDelete}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }