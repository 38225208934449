import { ALERT_TYPES } from '@/const/alert-types.enum'
import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    isOverlayLoaderActive: false,
    alertType: null,
    alertData: {},
    eventLogs: [],
    eventLogsMeta: {},
    packages: [],
    unprocessedPackages: [],
    packagesMeta: {},
    unprocessedPackagesMeta: {},
    progressData: [],
  },
  actions: {
    displayOverlayLoader({ commit }, payload) {
      commit('SET_OVERLAY_LOADER_VISIBILITY', payload)
    },
    displayAlert({ commit }, payload) {
      commit('SET_ALERT_DATA', payload)
    },
    async displaySuccessAlert({ commit, dispatch }, payload) {
      commit('SET_ALERT_TYPE', ALERT_TYPES.SUCCESS)
      await dispatch('displayAlert', payload)
    },
    async displayInfoAlert({ commit, dispatch }, payload) {
      commit('SET_ALERT_TYPE', ALERT_TYPES.INFO)
      await dispatch('displayAlert', payload)
    },
    async displayWarningAlert({ commit, dispatch }, payload) {
      commit('SET_ALERT_TYPE', ALERT_TYPES.WARNING)
      await dispatch('displayAlert', payload)
    },
    displayErrorAlert({ commit, dispatch }, payload) {
      commit('SET_ALERT_TYPE', ALERT_TYPES.ERROR)
      dispatch('displayAlert', payload.data)
    },
    /* *** EVENTS LOGS *** */
    async loadEventLogs(
      { commit, dispatch },
      {
        companyId,
        page = 1,
        limit = 10,
        sort,
        fromDate,
        toDate,
        eventTypes,
        events,
        users,
        packetTypes,
        serverTime,
        terminals,
        balanceHolders,
        servicePoints,
        deviceTypes,
        devices,
      }
    ) {
      const url = `/api/v1/companies/${companyId}/event-logs`
      let query = { page, limit }
      const optionalParams = {
        sort,
        fromDate,
        toDate,
        eventTypes,
        events,
        users,
        packetTypes,
        serverTime,
        terminals,
        balanceHolders,
        servicePoints,
        deviceTypes,
        devices,
      }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_EVENT_LOGS', data.data)
        commit('SET_EVENT_LOGS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadAllPackages(
      { commit, dispatch },
      {
        companyId,
        page = 1,
        limit = 10,
        sort = '',
        direction = '',
        packetTypes = '',
        terminals = '',
        fromDate = '',
        toDate = '',
        serverTime = 0,
      } = {}
    ) {
      try {
        const { data } = await api.get(
          `/api/v1/companies/${companyId}/terminals/packet-event-logs?page=${page}&limit=${limit}&sort=${sort}&direction=${direction}&packetTypes=${packetTypes}&terminals=${terminals}&fromDate=${fromDate}&toDate=${toDate}&serverTime=${serverTime}`
        )
        commit('SET_PACKAGES', data.data)
        commit('SET_PACKAGES_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadAllUnprocessedPackages(
      { commit, dispatch },
      { companyId, page = 1, limit = 10, sort = '', direction = '', fromDate = '', toDate = '' } = {}
    ) {
      try {
        const { data } = await api.get(
          `/api/v1/companies/${companyId}/terminals/unprocessed-packet-logs?page=${page}&limit=${limit}&sort=${sort}&direction=${direction}&fromDate=${fromDate}&toDate=${toDate}`
        )
        commit('SET_UNPROCESSED_PACKAGES', data.data)
        commit('SET_UNPROCESSED_PACKAGES_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async addProgressData({ commit }, payload) {
      const id = crypto.randomUUID()
      commit('ADD_PROGRESS_DATA', { ...payload, id })
      return id
    },
    async updateProgressItem({ commit }, payload) {
      commit('UPDATE_PROGRESS_ITEM', payload)
    },
    async deleteProgressItem({ commit }, payload) {
      commit('DELETE_PROGRESS_ITEM', payload)
    },
  },
  mutations: {
    SET_OVERLAY_LOADER_VISIBILITY(state, isActive) {
      state.isOverlayLoaderActive = isActive
    },
    SET_ALERT_TYPE(state, type) {
      state.alertType = type
    },
    SET_ALERT_DATA(state, data) {
      state.alertData = data
    },
    SET_EVENT_LOGS(state, data) {
      state.eventLogs = data
    },
    SET_EVENT_LOGS_META(state, data) {
      state.eventLogsMeta = data
    },
    SET_PACKAGES(state, data) {
      state.packages = data
    },
    SET_UNPROCESSED_PACKAGES(state, data) {
      state.unprocessedPackages = data
    },
    SET_PACKAGES_META(state, data) {
      state.packagesMeta = data
    },
    SET_UNPROCESSED_PACKAGES_META(state, data) {
      state.unprocessedPackagesMeta = data
    },
    ADD_PROGRESS_DATA(state, data) {
      state.progressData.push(data)
    },
    UPDATE_PROGRESS_ITEM(state, data) {
      state.progressData = [...state.progressData.map((item) => (item.id === data.id ? data : item))]
    },
    DELETE_PROGRESS_ITEM(state, data) {
      state.progressData = [...state.progressData.filter((item) => item.id !== data.id)]
    },
  },
  getters: {
    getErrorsText(state) {
      return state.alertData.errors || {}
    },
    getEventLogsCount(state) {
      return state.eventLogsMeta.total_count
    },
    getPackagesCount(state) {
      return state.packagesMeta.total_count
    },
    getUnprocessedPackagesCount(state) {
      return state.unprocessedPackagesMeta.total_count
    },
  },
}
