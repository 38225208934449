import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    promotions: [],
    promotionsMeta: {},
    promotionsArch: [],
    promotionsArchMeta: {},
    promotion: {},
  },
  actions: {
    async loadPromotions(
      { commit, dispatch },
      {
        companyId,
        isAdmin = false,
        page = 1,
        limit = 10,
        sort,
        isAvailableOnce,
        type,
        valueType,
        createdAt,
        updatedAt,
        startAt,
        expiredAt,
        active,
      }
    ) {
      const url = isAdmin ? `/api/v1/admin/${companyId}/promotions` : `/api/v1/companies/${companyId}/promotions`
      let query = { page, limit }
      const optionalParams = { sort, isAvailableOnce, type, valueType, createdAt, updatedAt, startAt, expiredAt, active }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key ?? !!value)),
      }
      try {
        const data = await api.get(url, { params: query })
        active ? commit('SET_PROMOTIONS', data) : commit('SET_PROMOTIONS_ARCH', data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async createPromotion({ commit, dispatch }, { companyId, isAdmin = false, ...payload }) {
      const url = isAdmin ? `/api/v1/admin/${companyId}/promotions` : `/api/v1/companies/${companyId}/promotions`
      try {
        const data = await api.post(url, payload.newPromotion)
        await dispatch('logs/displaySuccessAlert', { message: 'Акцію створено успішно!' }, { root: true })
        commit('SET_PROMOTION', data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async loadSelectedPromotion({ commit, dispatch }, { isAdmin = false, ...payload }) {
      const { companyId, promotionId } = payload
      const url = isAdmin
        ? `/api/v1/admin/${companyId}/promotions/${promotionId}`
        : `/api/v1/companies/${companyId}/promotions/${promotionId}`
      try {
        const { data } = await api.get(url)
        commit('SET_PROMOTION', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async updateSelectedPromotion({ commit, dispatch }, { isAdmin = false, companyId, id, ...payload }) {
      try {
        const url = isAdmin ? `/api/v1/admin/${companyId}/promotions/${id}` : `/api/v1/companies/${companyId}/promotions/${id}`
        const { data } = await api.put(url, payload.updatedPromotion)
        await dispatch('logs/displaySuccessAlert', { message: 'Акцію оновлено успішно!' }, { root: true })
        commit('SET_PROMOTION', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async deleteSelectedPromotion({ dispatch }, { isAdmin = false, ...payload }) {
      const { companyId, id } = payload
      const url = isAdmin ? `/api/v1/admin/${companyId}/promotions/${id}` : `/api/v1/companies/${companyId}/promotions/${id}`
      try {
        await api.delete(url)
        await dispatch('logs/displaySuccessAlert', { message: 'Акцію видалено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },

  mutations: {
    SET_PROMOTIONS(state, val) {
      state.promotions = val.data.data
      state.promotionsMeta = val.data.meta
    },

    SET_PROMOTIONS_ARCH(state, val) {
      state.promotionsArch = val.data.data
      state.promotionsArchMeta = val.data.meta
    },

    SET_PROMOTION(state, val) {
      state.promotion = val
    },
  },

  getters: {
    getPromotionsTotalCount(state) {
      return state.promotionsMeta.total_count
    },
    getPromotionsArchTotalCount(state) {
      return state.promotionsArchMeta.total_count
    },
  },
}
