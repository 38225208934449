<template>
  <v-dialog v-model="isActive" max-width="500px" @click:outside="$emit('no')">
    <template v-for="slot of Object.keys($slots)" v-slot:[slot]>
      <slot :name="slot" />
    </template>

    <v-card>
      <v-card-title class="text-h5 justify-center">{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('yes')">{{ yesButtonText }}</v-btn>
        <v-btn text @click="$emit('no')">{{ noButtonText }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteDialog',

  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: 'Видалити?',
    },

    message: {
      type: String,
      required: true,
    },

    yesButtonText: {
      type: String,
      required: false,
      default: 'Так',
    },

    noButtonText: {
      type: String,
      required: false,
      default: 'Ні',
    },
  },

  emits: ['yes', 'no'],

  data() {
    return {
      isActive: false,
    }
  },

  watch: {
    isOpened(val) {
      this.isActive = val
    },
  },
}
</script>
