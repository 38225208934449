<template>
  <PromotionsList :active="false" />
</template>

<script>
import PromotionsList from '@/components/marketing/promotions/PromotionsList'

export default {
  name: 'PromotionsArchList',
  components: { PromotionsList },
}
</script>
