<template>
  <v-container fluid>
    <v-card-title>Ця сторінка знаходится в процесі розробки</v-card-title>
  </v-container>
</template>

<script>
export default {
  name: 'PromotionsBannersSettings',
}
</script>
