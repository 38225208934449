<template>
  <v-container fluid>
    <v-card>
      <FormToolbar :title="`${user.first_name} ${user.last_name}`">
        <template v-slot:left>
          <BackButton />
          <v-toolbar-title>{{ user.first_name }} {{ user.last_name }}</v-toolbar-title>
          <v-chip v-if="getStatus" class="ml-3" :text-color="getStatus.color" small>
            <v-avatar left>
              <v-icon :color="getStatus.color" size="20">{{ getStatus.icon }}</v-icon>
            </v-avatar>
            <span class="text-caption">{{ getStatus.title }}</span>
          </v-chip>
        </template>

        <template v-slot:right>
          <div>
            <div class="font-italic font-weight-medium">Картка користувача</div>
            <div v-if="isGlobalAvail && user && user.id">
              <BonusPayroll :user-id="user.id" button-text="Нарахувати бонуси користувачу" @yes="bonusAdded" />
            </div>
          </div>
        </template>

        <template v-slot:extension>
          <v-tabs v-model="currentTab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="(item, index) in tabs" :key="index">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
      </FormToolbar>

      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <v-card flat color="blue-grey lighten-5">
            <component
              v-if="isUserExist"
              :is="item.component"
              :user="user"
              :user-id="user.id"
              :is-admin="isAdmin"
              :bonuses="bonuses"
              :currentItem="user"
              :employers="selectedUserEmployers"
              :isDataSaved="isDataSaved"
              :currentTabName="currentTabName"
              :isLoaded="isLoaded"
              :editCard="editCard"
              @saveData="saveData"
              @changes="editCard = true"
            ></component>
            <v-container v-show="showEditButtons" fluid>
              <div class="d-flex justify-end">
                <v-btn v-if="isAdmin && !editCard" @click="editCard = true" class="mr-2" small> Редагувати </v-btn>
                <v-btn v-if="editCard" @click="editCard = false" class="mr-2" small>
                  {{ 'Скасувати' }}
                </v-btn>
                <v-btn v-show="editCard" @click="showModal = true" :disabled="isFileLoading" color="primary" small
                  >Зберегти</v-btn
                >
              </div>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card v-if="currentTabName === 'info' && isUserExist" class="mt-5" flat>
      <Logs :preselected-users="[this.user]" disable-users />
    </v-card>

    <v-card v-if="currentTabName === 'bonuses'" class="mt-5" flat>
      <Payments />
    </v-card>

    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="isDataSaved = true" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ConvertStatusesTypes from '@/mixins/convertStatusesTypes'
import BackButton from '@/components/common/BackButton'
import FormToolbar from '@/components/common/forms/FormToolbar'
import BonusPayroll from '@/components/marketing/clients/BonusPayroll'

export default {
  name: 'UserCard',

  mixins: [ConvertStatusesTypes],

  components: {
    BonusPayroll,
    FormToolbar,
    BackButton,
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
    GeneralInfo: () => import('@/components/common/users/UserGeneralInfo'),
    Bonuses: () => import('@/components/common/users/Bonuses'),
    Logs: () => import('@/components/common/Logs'),
    Payments: () => import('@/components/common/Payments'),
  },

  data() {
    return {
      showModal: false,
      currentTab: 0,
      isDataSaved: false,
      isLoaded: false,
      editCard: false,
      tabs: [
        {
          name: 'info',
          title: 'Інфо',
          component: 'GeneralInfo',
        },
        {
          name: 'bonuses',
          title: 'Ваші бонуси',
          component: 'Bonuses',
        },
      ],
    }
  },

  computed: {
    ...mapState('admin', ['user', 'isFileLoading']),
    ...mapState('profile', ['selectedUserEmployers', 'userRoles']),
    ...mapState('dictionaries', ['technicalContactTypesDictionary', 'personalContactTypesDictionary', 'rolesDictionary']),
    ...mapState('bonuses', ['bonuses']),

    currentCompanyId() {
      return this.$route.params.id
    },
    currentUserId() {
      return this.$route.params.userId
    },
    isUserExist() {
      return Object.entries(this.user).length
    },
    isAdmin() {
      return this.userRoles.includes(this.rolesDictionary.ADMIN)
    },
    showEditButtons() {
      return this.currentTabName !== 'bonuses'
    },
    currentTabName() {
      return this.tabs[this.currentTab].name
    },
    isGlobalAvail() {
      return this.userRoles.includes(this.rolesDictionary.ADMIN) || this.userRoles.includes(this.rolesDictionary.SUPPORT)
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.clearData()
  },

  methods: {
    ...mapActions('profile', ['loadSelectedUserEmployers']),
    ...mapActions('admin', ['loadSelectedUser', 'updateSelectedUser']),
    ...mapActions('logs', ['displayWarningAlert']),
    ...mapActions('bonuses', ['loadUserBonuses']),
    ...mapMutations('admin', ['SET_SELECTED_USER']),

    async initialize() {
      if (this.currentCompanyId !== 'undefined') {
        const payload = {
          companyId: this.currentCompanyId,
          userId: this.currentUserId,
        }
        try {
          await Promise.all([
            this.loadSelectedUser(payload),
            this.loadSelectedUserEmployers(payload),
            this.loadUserBonuses(payload),
          ])
        } finally {
          this.isLoaded = true
        }
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
    async saveData(updatedUser) {
      delete updatedUser.id
      const payload = {
        userId: this.currentUserId,
        updatedUser,
      }
      this.showModal = false
      this.isLoaded = false
      await this.updateSelectedUser(payload)
      await this.initialize()
      this.isDataSaved = false
      this.editCard = false
    },
    async bonusAdded() {
      const payload = {
        companyId: this.currentCompanyId,
        userId: this.currentUserId,
      }
      await this.loadUserBonuses(payload)
    },
    clearData() {
      this.SET_SELECTED_USER({})
    },
  },
}
</script>

<style scoped></style>
