import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    devices: [],
    devicesMeta: {},
    device: {},
  },
  actions: {
    async loadDevices(
      { commit, dispatch },
      { companyId, page = 1, limit = 10, sort, search, forSearch = 0, ...restFilterParams } = {}
    ) {
      const url = forSearch ? `/api/v1/companies/${companyId}/devices/forSearch` : `/api/v1/companies/${companyId}/devices`
      let query = { page, limit }
      const optionalParams = { sort, search, ...restFilterParams }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key ?? !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_DEVICES', data.data)
        commit('SET_DEVICES_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedDevice({ commit, dispatch }, payload) {
      try {
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/devices/${payload.deviceId}`)
        commit('SET_SELECTED_DEVICE', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createNewDevice({ commit, dispatch }, payload) {
      try {
        const { data } = await api.post(`/api/v1/companies/${payload.companyId}/devices`, payload.newDevice)
        await dispatch('logs/displaySuccessAlert', { message: 'Пристрій створено успішно!' }, { root: true })
        commit('SET_CREATED_DEVICE', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedDevice({ commit, dispatch }, payload) {
      try {
        const { data } = await api.put(
          `/api/v1/companies/${payload.companyId}/devices/${payload.deviceId}`,
          payload.updatedDevice
        )
        await dispatch('logs/displaySuccessAlert', { message: 'Пристрій оновлено успішно!' }, { root: true })
        commit('SET_UPDATED_DEVICE', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteSelectedDevice({ commit, dispatch }, payload) {
      try {
        await api.delete(`/api/v1/companies/${payload.companyId}/devices/${payload.deviceId}`)
        await dispatch('logs/displayInfoAlert', { message: 'Автомат видалено!' }, { root: true })
        commit('SET_DELETED_DEVICE')
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_DEVICES(state, val) {
      state.devices = val
    },
    SET_DEVICES_META(state, meta) {
      state.devicesMeta = meta || {}
    },
    SET_SELECTED_DEVICE(state, device) {
      state.device = device || {}
    },
    SET_CREATED_DEVICE(state, device) {
      console.log(state.device)
      console.log(device)
    },
    SET_UPDATED_DEVICE(state, device) {
      state.device = device || {}
    },
    SET_DELETED_DEVICE(state) {
      state.device = {}
    },
  },
  getters: {
    getDevicesTotalCount(state) {
      return state.devicesMeta.total_count
    },
    getTerminalLinkedToDevice(state) {
      return state.device?.terminal || {}
    },
    getServicePointLinkedToDevice(state) {
      return state.device?.terminal?.service_point || {}
    },
  },
}
