<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>T{{ currentTerminalId }}</v-toolbar-title>
        <v-chip v-if="getStatus" class="ml-3" :text-color="getStatus.color" small>
          <v-avatar left>
            <v-icon :color="getStatus.color" size="20">{{ getStatus.icon }}</v-icon>
          </v-avatar>
          <span class="text-caption">{{ getStatus.title }}</span>
        </v-chip>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">Картка термінала</div>

        <template v-slot:extension>
          <v-tabs v-model="currentTab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="(item, index) in tabs" :key="index">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <v-card flat color="blue-grey lighten-5">
            <ProgressCircular :isLoaded="!!isTerminalExist" />
            <component
              v-if="isTerminalExist"
              :is="item.component"
              :terminal="terminal"
              :isDataSaved="isDataSaved"
              :currentTab="currentTab"
              :isLoaded="isLoaded"
              :editCard="editCard"
              @saveData="saveData"
            ></component>
            <v-container v-show="showEditButtons" fluid>
              <div class="d-flex justify-end">
                <v-btn @click="editCard = !editCard" class="mr-2" small>
                  {{ !editCard ? 'Редагувати' : 'Повернутись' }}
                </v-btn>
                <v-btn v-show="editCard" @click="showModal = true" color="primary" small>Зберегти</v-btn>
              </div>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card v-if="!userRoles.includes(rolesDictionary.ACCOUNTANT)" class="mt-5" flat>
      <v-toolbar tile elevation="1">
        <v-tabs v-model="logTab">
          <v-tab
            v-for="tab in LOG_TABS"
            :key="tab.name"
            @click="$router.replace({ query: { logTab: tab.name } })"
            :tab-value="tab.name"
          >
            {{ tab.label }}
          </v-tab>
        </v-tabs>
      </v-toolbar>
      <Logs :instanceId="+currentTerminalId" />
    </v-card>

    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="isDataSaved = true" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mergeObjectsDeep } from '@/helpers/mergeObjects'
import ConvertStatusesTypes from '@/mixins/convertStatusesTypes'
import { TERMINAL_SCHEMA } from '@/const/apiSchemas'
import BackButton from '@/components/common/BackButton.vue'
import SetLogTabs from '@/mixins/setLogTabs'

export default {
  name: 'TerminalCard',

  components: {
    BackButton,
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
    Online: () => import('@/components/administration/terminal/Online'),
    Devices: () => import('@/components/administration/terminal/Devices'),
    // Firmwares: () => import('@/components/administration/terminal/Firmwares'),
    Goods: () => import('@/components/administration/terminal/Goods'),
    Logs: () => import('@/components/common/Logs'),
    ProgressCircular: () => import('@/components/common/ProgressCircular'),
  },

  mixins: [ConvertStatusesTypes, SetLogTabs],

  data() {
    return {
      showModal: false,
      currentTab: 1,
      isDataSaved: false,
      isLoaded: false,
      editCard: false,
      defaultItem: { ...TERMINAL_SCHEMA },
      tabs: [
        {
          title: 'online',
          component: 'Online',
        },
        {
          title: 'Пристрої',
          component: 'Devices',
        },
        /*{
          title: 'Прошивки',
          component: 'Firmwares',
        },*/
        {
          title: 'Товари',
          component: 'Goods',
        },
      ],
    }
  },

  computed: {
    ...mapState('terminals', ['terminal']),
    currentCompanyId() {
      return this.$route.params.id
    },
    currentTerminalId() {
      return this.$route.params.terminalId
    },
    isTerminalExist() {
      return Object.entries(this.terminal).length
    },
    showEditButtons() {
      return this.currentTab === 1
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.SET_SELECTED_TERMINAL(null)
  },

  methods: {
    ...mapActions('terminals', ['loadSelectedTerminal', 'updateSelectedTerminal']),
    ...mapActions('devices', ['loadDevices']),
    ...mapActions('logs', ['displayWarningAlert']),
    ...mapMutations('terminals', ['SET_SELECTED_TERMINAL']),

    async initialize() {
      if (this.currentCompanyId !== 'undefined') {
        const payload = {
          companyId: this.currentCompanyId,
          terminalId: this.currentTerminalId,
        }
        await Promise.all([this.loadSelectedTerminal(payload), this.loadDevices({ companyId: this.currentCompanyId })])
        this.isLoaded = true
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
      this.logTab = this.$route.query?.logTab || this.LOG_TABS[0].name
    },
    async saveData(updatedTerminal) {
      updatedTerminal = mergeObjectsDeep(this.defaultItem, updatedTerminal)
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: this.currentTerminalId,
        updatedTerminal,
      }
      this.showModal = false
      this.isLoaded = false
      await this.updateSelectedTerminal(payload)
      this.isDataSaved = false
      this.isLoaded = true
      this.editCard = false
    },
  },
}
</script>

<style scoped></style>
