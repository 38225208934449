<template>
  <v-container fluid>
    <v-card>
      <Payments />
    </v-card>
  </v-container>
</template>

<script>
import Payments from '@/components/common/Payments'

export default {
  name: 'PaymentsList',

  components: { Payments },
}
</script>
