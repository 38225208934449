<template>
  <v-container fluid>
    <v-card flat color="blue-grey lighten-5">
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>Інкасація #{{ encashment.id }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">Картка інкасації</div>
      </v-toolbar>

      <EncashmentCardView
        v-if="isLoaded && isEncashmentExist && !isEditable"
        :encashment="encashment"
        :prevEncashment="prevEncashment"
        :currentCompanyId="currentCompanyId"
      />
      <EncashmentCardCRU
        v-if="isLoaded && isEditable"
        :encashment="encashment"
        :prevEncashment="prevEncashment"
        :staff="staff"
        :currentCompanyId="currentCompanyId"
        :isEditCard="isEditCard"
        :isDataSaved="isDataSaved"
        @selectTerminal="getLastEncashment"
        @saveData="saveData"
      />

      <v-container fluid>
        <div class="d-flex justify-end">
          <v-btn v-if="!isEditable" :to="{ name: 'Encashments' }" class="mr-2" small> Назад </v-btn>
          <v-btn v-else @click="$router.go(-1)" class="mr-2" small>Назад</v-btn>
          <v-btn v-if="!isEditable" @click="navigateToEditSection" small>Редагувати</v-btn>
          <v-btn v-else color="primary" @click="showModal = true" small>Зберегти</v-btn>
        </div>
      </v-container>
    </v-card>
    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="isDataSaved = true" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import { isObjectEmpty } from '@/helpers/isObjectEmpty'
import BackButton from '@/components/common/BackButton.vue'

export default {
  name: 'EncashmentCard',

  components: {
    BackButton,
    EncashmentCardView: () => import('@/components/finance/encashments/EncashmentCardView'),
    EncashmentCardCRU: () => import('@/components/finance/encashments/EncashmentCardCRU'),
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    isLoaded: false,
    showModal: false,
    isDataSaved: false,
  }),

  computed: {
    ...mapState('encashments', ['encashment', 'prevEncashment']),
    ...mapState('staff', ['staff']),

    currentCompanyId() {
      return this.$route.params.id
    },
    currentEncashmentId() {
      return this.$route.params.encashmentId
    },
    isEditable() {
      return this.$route.hash === '#edit' || this.$route.hash === '#create'
    },
    isCreateCard() {
      return this.$route.hash === '#create'
    },
    isEditCard() {
      return this.$route.hash === '#edit'
    },
    isEncashmentExist() {
      return !isObjectEmpty(this.encashment)
    },
  },

  watch: {
    currentEncashmentId() {
      this.initialize()
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.SET_SELECTED_ENCASHMENT({})
    this.SET_PREV_ENCASHMENT({})
  },

  methods: {
    ...mapActions('encashments', [
      'loadSelectedEncashment',
      'loadPrevEncashment',
      'loadLastEncashment',
      'createNewEncashment',
      'updateSelectedEncashment',
    ]),
    ...mapActions('staff', ['loadStaff']),
    ...mapActions('logs', ['displayWarningAlert']),
    ...mapMutations('encashments', ['SET_SELECTED_ENCASHMENT', 'SET_PREV_ENCASHMENT']),

    async initialize() {
      if (this.currentCompanyId !== 'undefined') {
        const staffPayload = {
          companyId: this.currentCompanyId,
        }
        if (this.currentEncashmentId) {
          const encashmentPayload = {
            companyId: this.currentCompanyId,
            encashmentId: this.currentEncashmentId,
          }
          await Promise.all([this.loadSelectedEncashment(encashmentPayload), this.loadStaff(staffPayload)])
          await this.getPrevEncashment()
          this.isLoaded = true
        } else {
          await this.loadStaff(staffPayload)
          this.isLoaded = true
        }
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
    async saveData(encashment) {
      this.showModal = false
      if (this.isEditCard) {
        delete encashment.id
        const payload = {
          companyId: this.currentCompanyId,
          encashmentId: this.currentEncashmentId,
          encashment,
        }
        await this.updateSelectedEncashment(payload)
      } else if (this.isCreateCard) {
        const payload = {
          companyId: this.currentCompanyId,
          encashment,
        }
        await this.createNewEncashment(payload)
      }
      this.$router.go(-1)
      this.isDataSaved = false
    },
    async getPrevEncashment() {
      if (!this.encashment.prev_encashment_id) return
      const payload = {
        companyId: this.currentCompanyId,
        encashmentId: this.encashment.prev_encashment_id,
      }
      await this.loadPrevEncashment(payload)
    },
    async getLastEncashment(terminalId) {
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: terminalId,
      }
      await this.loadLastEncashment(payload)
    },
    navigateToEditSection() {
      this.$router.push({
        name: 'EncashmentCard',
        params: { id: this.currentCompanyId.toString(), encashmentId: this.currentEncashmentId.toString() },
        hash: '#edit',
      })
    },
  },
}
</script>

<style scoped lang="scss">
.custom-field {
  max-width: 500px !important;
}
</style>
