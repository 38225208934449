<template>
  <v-menu v-model="showMenu" :close-on-content-click="closeOnContentClick" bottom left>
    <template v-slot:activator="{ on, attrs }">
      <!--      <v-btn dark icon v-bind="attrs" v-on="on">-->
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click="closeMenu">
        <v-list-item-title>Інтерфейс Клієнта</v-list-item-title>
      </v-list-item>
      <v-list-item @click="closeMenu" :to="`${ROUTER_LINKS.ADMIN_MAIN}`">
        <v-list-item-title>Інтерфейс Адміністратора</v-list-item-title>
      </v-list-item>
      <v-list-item>
        <PagingSelect
          :options="companiesForSearch"
          :options-params="companiesOptions"
          :options-meta="companiesForSearchMeta"
          item-text="name"
          hide-details
          dense
          label="Оберіть компанію"
          @change="selectCurrentCompany"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { ROUTER_LINKS } from '@/const/router-links.enum'
import { mapActions, mapState } from 'vuex'
import { setCurrentCompany } from '@/utils/setCurrentCompany'

export default {
  name: 'SelectCompanyMenu',

  components: {
    PagingSelect: () => import('@/components/common/PagingSelect'),
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    companiesOptions: null,
    closeOnContentClick: false,
    showMenu: false,
  }),

  computed: {
    ...mapState('companies', ['currentCompany', 'companiesForSearch', 'companiesForSearchMeta']),
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('companies', ['loadCurrentCompany', 'getCompanies']),
    ...mapActions('profile', ['setUserRoles']),

    initialize() {
      this.companiesOptions = {
        loadingFunction: this.getCompanies,
        payload: {
          forSearch: 1,
        },
      }
    },
    async selectCurrentCompany(company) {
      this.closeMenu()
      setCurrentCompany(company)
      await Promise.all([this.loadCurrentCompany(company.id), this.setUserRoles()])
      await this.$router.push({
        name: this.currentCompany && this.currentCompany.status !== 2 ? 'CompanyDashboard' : 'CompanyLocked',
        params: { id: company.id },
      })
    },
    closeMenu() {
      this.showMenu = false
    },
  },
}
</script>
