<template>
  <v-container fluid>
    <v-card>
      <FormToolbar title="Акції">
        <template v-slot:left>
          <v-tabs v-model="currentTab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="item in tabs"
              :key="item.name"
              :tab-value="item.name"
              @click="$router.replace({ query: { tab: item.name } })"
            >
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
        <template v-slot:right>
          <router-link :to="{ name: 'PromotionEdit' }">
            <v-btn color="primary" :disabled="currentTab === 1" small> Додати акцію </v-btn>
          </router-link>
        </template>
      </FormToolbar>

      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="item in tabs" :key="item.name" :value="item.name">
          <component :is="item.component"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card v-if="currentTab === 'list'" class="mt-5" flat>
      <Payments />
    </v-card>
  </v-container>
</template>

<script>
import FormToolbar from '@/components/common/forms/FormToolbar'

export default {
  name: 'Promotions',

  components: {
    FormToolbar,
    PromotionsList: () => import('@/components/marketing/promotions/PromotionsList'),
    PromotionsArchList: () => import('@/components/marketing/promotions/PromotionsArchList'),
    PromotionsBannersSettings: () => import('@/components/marketing/promotions/PromotionsBannersSettings'),
    Payments: () => import('@/components/common/Payments'),
  },

  data() {
    return {
      currentTab: 'list',
      tabs: [
        {
          name: 'list',
          title: 'Поточні акції',
          component: 'PromotionsList',
        },
        {
          name: 'arch',
          title: 'Архівні акції',
          component: 'PromotionsArchList',
        },
        {
          name: 'banners',
          title: 'Налаштування банерів для мобайла',
          component: 'PromotionsBannersSettings',
        },
      ],
    }
  },

  computed: {
    currentTabName() {
      return this.tabs[this.currentTab].name
    },
  },

  created() {
    this.currentTab = this.$route.query?.tab || this.tabs[0].name
  },
}
</script>
