<template>
  <v-card>
    <BonusesTable v-if="localBonuses.length" :bonuses="localBonuses5" />
    <v-row v-else>
      <v-col cols="12" class="my-5">
        <span class="font-weight-bold mx-3">У користувача поки що нарахованих бонусів немає</span>
      </v-col>
    </v-row>
    <v-expansion-panels v-if="localBonusesRest.length" focusable accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            Показати ще <strong>{{ localBonusesRest.length }}</strong> бонусів...
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <BonusesTable :bonuses="localBonusesRest" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import BonusesTable from '@/components/support/users/BonusesTable'

export default {
  name: 'Bonuses',

  components: { BonusesTable },

  props: {
    bonuses: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API_URL,
    }
  },

  computed: {
    currentCompanyId() {
      return this.$route.params.id
    },

    localBonuses() {
      const bonusesArr = [...this.bonuses]
      bonusesArr.sort((a, b) => {
        if (!a.company_name) return a
        if (!b.company_name) return b
        return b.amount - a.amount
      })
      bonusesArr.forEach((item) => {
        item.company_logo = `${this.baseURL}/${item.company_logo}`
        item.servicePoints = []
      })
      if (bonusesArr.length) {
        if (!bonusesArr[0].company_id) bonusesArr[0].company_name = 'MATE'
      }
      return bonusesArr
    },

    localBonuses5() {
      return this.localBonuses.slice(0, 5)
    },

    localBonusesRest() {
      return this.localBonuses?.slice(5, this.localBonuses.length) || []
    },
  },
}
</script>
