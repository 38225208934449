<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="promotionsData"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th class="text-center" style="border-right: 1px solid rgba(0, 0, 0, 0.12)" colspan="10">Акція</th>
            <th class="text-center" colspan="3">Місце</th>
          </tr>
        </thead>
      </template>
      <template v-slot:item.id="{ item }">
        <router-link
          :to="{
            name: 'PromotionCard',
            params: { promotionId: item.id },
          }"
          class="text-decoration-none"
        >
          {{ item.id }}
        </router-link>
      </template>
      <template v-slot:item.start_at="{ item }">
        {{ item.start_at | getShortDate }}
        <sup>{{ item.start_at | getShortTime }}</sup>
      </template>
      <template v-slot:item.expired_at="{ item }">
        <span :class="{ 'red--text text--lighten-1': isDateExpired(item.expired_at) }">
          {{ item.expired_at | getShortDate }}
          <sup>{{ item.expired_at | getShortTime }}</sup>
        </span>
      </template>
      <template v-slot:item.type="{ item }">{{ getPromType(item.type) }}</template>
      <template v-slot:item.is_available_once="{ item }"
        >{{ item.is_available_once ? 'Разова' : 'Постійна' }} {{ item.company ? '' : 'глобальна' }}
      </template>
      <template v-slot:item.is_active="{ item }">
        <CircleIndicator :is-on="item.is_active" />
      </template>
      <template v-slot:item.users="{ item }">
        <div v-for="user in item.users" :key="user.id">
          <router-link
            :to="{
              name: 'UserCard',
              params: { id: currentCompanyId, userId: user.id },
            }"
            class="text-decoration-none"
          >
            {{ user.first_name }} {{ user.last_name }}
          </router-link>
        </div>
      </template>
      <template v-slot:item.value_type="{ item }">{{ item.value_type === 1 ? 'грн.' : '%' }}</template>
      <template v-slot:item.amount="{ item }">
        <div v-if="!!item.conditions.length">{{ getConditionsMinMax(item.conditions) }}</div>
        <div v-else>{{ item.amount }}</div>
      </template>
      <template v-slot:item.devices="{ item }">
        <div v-for="(device, index) in item.devices" :key="index">
          <router-link :to="{ name: 'DeviceCard', params: { deviceId: device.id } }" class="text-decoration-none">
            <span>{{ getDeviceTitle(device.type) }}{{ device.id }}</span>
          </router-link>
        </div>
      </template>
      <template v-slot:item.service_points="{ item }">
        <div v-for="point in item.service_points" :key="point.id">
          <router-link
            :to="{
              name: 'ServicePointCard',
              params: { id: currentCompanyId, servicePointId: point.id },
            }"
            class="text-decoration-none"
          >
            {{ point.name }}
          </router-link>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="flex flex-row justify-space-between">
          <v-tooltip :open-on-focus="false" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" small v-bind="attrs" @click="editItem(item)" v-on="on"> mdi-pencil-outline</v-icon>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip v-if="active" :open-on-focus="false" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" small v-bind="attrs" @click="deletePromotion(item.id)" v-on="on"> mdi-delete-outline </v-icon>
            </template>
            <span>Перемістити в архів</span>
          </v-tooltip>
          <v-tooltip :open-on-focus="false" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" @click="navigateToPromotionCard(item)" v-on="on"> mdi-eye-outline</v-icon>
            </template>
            <span>Переглянути</span>
          </v-tooltip>
          <slot name="append"></slot>
        </div>
      </template>
    </v-data-table>
    <AskDialog
      :message="deleteMessages.msg"
      :sub-message="deleteMessages.subMsg"
      :is-opened="dialogDelete"
      @yes="deletePromotionConfirm"
      @no="closeDelete"
    />
  </div>
</template>

<script>
import CircleIndicator from '@/components/common/CircleIndicator'
import convertDate from '@/mixins/convertDate'
import sortUtils from '@/mixins/sortUtils'
import conditionsMinMax from '@/mixins/conditionsMinMax'
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment/moment'
import convertDevicesTypes from '@/mixins/convertDevicesTypes'

export default {
  name: 'PromotionsList',

  components: {
    CircleIndicator,
    AskDialog: () => import('@/components/dialogs/AskDialog'),
  },

  mixins: [convertDate, sortUtils, conditionsMinMax, convertDevicesTypes],

  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      headers: [
        { text: 'ID', align: 'left', sortable: true, value: 'id' },
        {
          text: 'Термін дії з',
          align: 'left',
          sortable: true,
          value: 'start_at',
        },
        {
          text: 'Термін дії до',
          align: 'left',
          sortable: true,
          value: 'expired_at',
        },
        {
          text: 'Вид акції',
          align: 'left',
          sortable: false,
          value: 'is_available_once',
        },
        { text: 'Тип акції', align: 'left', sortable: true, value: 'type' },
        { text: 'Назва акції', align: 'left', sortable: true, value: 'title' },
        {
          text: 'Включено',
          align: 'center',
          sortable: false,
          value: 'is_active',
        },
        { text: 'Клієнти', align: 'left', sortable: false, value: 'users' },
        {
          text: 'Вид бонуса',
          align: 'left',
          sortable: false,
          value: 'value_type',
        },
        {
          text: 'Розмір бонуса',
          align: 'right',
          sortable: true,
          value: 'amount',
        },
        {
          text: 'Торгівельна точка',
          align: 'left',
          sortable: false,
          value: 'service_points',
        },
        { text: 'Автомат', align: 'left', sortable: false, value: 'devices' },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '125px',
          align: 'center',
        },
      ],
      options: {},
      payload: null,
      dialogDelete: false,
      loading: false,
      clickedPromotionId: -1,
      clickedPromotion: null,
      isAdmin: true, // Todo: make this param computed for correct endpoint usage in future
    }
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete()
    },

    options: {
      handler(val) {
        this.payload = {
          ...this.payload,
          page: val.page,
          limit: val.itemsPerPage,
          sort: this.sortObject,
        }
        this.paginateTo()
      },
      deep: true,
    },
  },

  created() {
    this.initialize()
  },

  computed: {
    ...mapState('promotions', ['promotions', 'promotionsMeta', 'promotionsArch', 'promotionsArchMeta', 'promotion']),
    ...mapState('dictionaries', ['globalPromotionsDictionary', 'companyPromotionsDictionary', 'machinesTypesDictionary']),
    ...mapGetters('promotions', ['getPromotionsTotalCount', 'getPromotionsArchTotalCount']),

    currentCompanyId() {
      return this.$route.params.id
    },

    promotionsData() {
      return this.active ? this.promotions : this.promotionsArch
    },

    totalCount() {
      return this.active ? this.getPromotionsTotalCount : this.getPromotionsArchTotalCount
    },

    deleteMessages() {
      return this.$route.query.tab === 'list'
        ? { msg: 'Перемістити в архів?', subMsg: 'Ви впевнені, що хочете перемістити в архів обрану акцію?' }
        : { msg: 'Видалити?', subMsg: 'Ви впевнені, що хочете видалити обрану акцію?' }
    },
  },

  methods: {
    ...mapActions('promotions', ['loadPromotions', 'updateSelectedPromotion', 'deleteSelectedPromotion']),

    initialize() {
      this.payload = {
        companyId: this.currentCompanyId,
        isAdmin: this.isAdmin,
        active: this.active ? 1 : 0,
      }
    },

    async paginateTo(both = false) {
      this.loading = true
      try {
        if (both) {
          await this.loadPromotions({ ...this.payload, active: 1 })
          await this.loadPromotions({ ...this.payload, active: 0 })
        } else {
          await this.loadPromotions(this.payload)
        }
      } finally {
        this.loading = false
      }
    },

    isDateExpired(date) {
      return moment(date) < new Date()
    },

    getPromType(type) {
      const prom = this.companyPromotionsDictionary.find((item) => item.value === type)
      return prom?.name || null
    },

    editItem(item) {
      this.$router.push({
        name: 'PromotionEdit',
        params: { promotionId: item.id },
      })
    },

    close() {
      this.dialog = false
    },

    deletePromotion(id) {
      this.clickedPromotionId = id
      this.clickedPromotion = this.promotionsData.find((item) => item.id === id)
      this.dialogDelete = true
    },

    async deletePromotionConfirm() {
      this.loading = true
      const payload = {
        companyId: this.currentCompanyId,
        isAdmin: this.isAdmin,
        id: this.clickedPromotionId,
      }
      try {
        this.$route.query.tab === 'list'
          ? await this.updateSelectedPromotion({ ...payload, updatedPromotion: { ...this.clickedPromotion, is_active: false } })
          : await this.deleteSelectedPromotion(payload)
      } finally {
        this.loading = false
        this.closeDelete()
        await this.paginateTo(true)
      }
    },

    closeDelete() {
      this.dialogDelete = false
      this.clickedPromotionId = null
      this.editedIndex = -1
    },

    navigateToPromotionCard(item) {
      this.$router.push({
        name: 'PromotionCard',
        params: {
          id: this.currentCompanyId.toString(),
          promotionId: item.id.toString(),
        },
      })
    },
  },
}
</script>
