import { payload2query } from '@/utils/payload2query'
import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    payments: [],
    paymentsMeta: {},
    payment: {},
    statisticChartPayload: {},
    statisticChartDataPayments: {},
    statisticChartDataPaymentsTypes: {},
    statisticChartDataPaymentsStatuses: {},
    statisticChartDataAverageCheck: {},
    statisticChartDataUniqueClients: {},
    isChartLoaded: false,
  },
  actions: {
    async loadCompanyPayments(
      { commit, dispatch, rootState },
      { companyId, page = 1, limit = 10, sort, search, ...restFilterParams } = {}
    ) {
      const roles = rootState.dictionaries.rolesDictionary
      const userRoles = rootState.profile.userRoles
      const requiredRoles = [roles.ADMIN, roles.SUPPORT]
      const hasAnyRequiredRole = requiredRoles.some((role) => userRoles.includes(role))
      const url = hasAnyRequiredRole ? `/api/v1/companies/${companyId}/payments/all` : `/api/v1/companies/${companyId}/payments`
      let query = { page, limit }
      const optionalParams = { sort, search, ...restFilterParams }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      if (optionalParams.createdBy === null) query.createdBy = ''
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_PAYMENTS', data.data)
        commit('SET_PAYMENTS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedPayment({ commit, dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/payments/${payload.paymentId}`)
        commit('SET_SELECTED_PAYMENT', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      } finally {
        dispatch('logs/displayOverlayLoader', false, { root: true })
      }
    },
    async createTechnicalPayment({ dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        await api.post(
          `/api/v1/companies/${payload.companyId}/payments/technical?devices=${payload.devices}&terminals=${payload.terminals}`,
          payload.technicalPayment
        )
        await dispatch('logs/displaySuccessAlert', { message: 'Технічний платіж створено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      } finally {
        await dispatch('logs/displayOverlayLoader', false, { root: true })
      }
    },
    async loadStatisticPayments({ commit, dispatch }, payload) {
      try {
        commit('SET_STATISTIC_CHART_STATUS', false)
        const query = payload2query(payload.data)
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/payments/statistic/payments-action`, {
          params: query,
        })
        commit('SET_STATISTIC_CHART_DATA_PAYMENTS', data)
        commit('SET_STATISTIC_CHART_STATUS', true)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadStatisticPaymentsTypes({ commit, dispatch }, payload) {
      try {
        commit('SET_STATISTIC_CHART_STATUS', false)
        const query = payload2query(payload.data)
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/payments/statistic/payment-types`, {
          params: query,
        })
        commit('SET_STATISTIC_CHART_DATA_PAYMENTS_TYPES', data)
        commit('SET_STATISTIC_CHART_STATUS', true)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadStatisticPaymentsStatuses({ commit, dispatch }, payload) {
      try {
        commit('SET_STATISTIC_CHART_STATUS', false)
        const query = payload2query(payload.data)
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/payments/statistic/payment-statuses`, {
          params: query,
        })
        commit('SET_STATISTIC_CHART_DATA_PAYMENTS_STATUSES', data)
        commit('SET_STATISTIC_CHART_STATUS', true)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadStatisticAverageCheck({ commit, dispatch }, payload) {
      try {
        commit('SET_STATISTIC_CHART_STATUS', false)
        const query = payload2query(payload.data)
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/payments/statistic/average-check`, {
          params: query,
        })
        commit('SET_STATISTIC_CHART_DATA_AVERAGE_CHECK', data)
        commit('SET_STATISTIC_CHART_STATUS', true)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadStatisticUniqueClients({ commit, dispatch }, payload) {
      try {
        commit('SET_STATISTIC_CHART_STATUS', false)
        const query = payload2query(payload.data)
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/payments/statistic/unique-clients`, {
          params: query,
        })
        commit('SET_STATISTIC_CHART_DATA_UNIQUE_CLIENTS', data)
        commit('SET_STATISTIC_CHART_STATUS', true)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async refundPayment({ dispatch }, payload) {
      const url = `/api/v1/companies/${payload.companyId}/payments/${payload.paymentId}/refund`
      try {
        await api.put(url)
        await dispatch('logs/displaySuccessAlert', { message: 'Платіж успішно повернуто!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_PAYMENTS(state, val) {
      state.payments = val
    },
    SET_PAYMENTS_META(state, paymentsMeta) {
      state.paymentsMeta = paymentsMeta || {}
    },
    SET_SELECTED_PAYMENT(state, payment) {
      state.payment = payment || {}
    },
    SET_STATISTIC_CHART_PAYLOAD(state, chartPayload) {
      state.statisticChartPayload = chartPayload || {}
    },
    SET_STATISTIC_CHART_DATA_PAYMENTS(state, chartData) {
      state.statisticChartDataPayments = chartData || {}
    },
    SET_STATISTIC_CHART_DATA_PAYMENTS_TYPES(state, chartData) {
      state.statisticChartDataPaymentsTypes = chartData || {}
    },
    SET_STATISTIC_CHART_DATA_PAYMENTS_STATUSES(state, chartData) {
      state.statisticChartDataPaymentsStatuses = chartData || {}
    },
    SET_STATISTIC_CHART_DATA_AVERAGE_CHECK(state, chartData) {
      state.statisticChartDataAverageCheck = chartData || {}
    },
    SET_STATISTIC_CHART_DATA_UNIQUE_CLIENTS(state, chartData) {
      state.statisticChartDataUniqueClients = chartData || {}
    },
    SET_STATISTIC_CHART_STATUS(state, status) {
      state.isChartLoaded = status
    },
  },
  getters: {
    getCompanyPaymentsTotalCount(state) {
      return state.paymentsMeta.total_count
    },
    getCompanyPaymentsWithDictionaries(state, getters, rootState) {
      const machines = rootState['dictionaries'].machinesTypesDictionary
      const types = rootState['dictionaries'].paymentTypesDictionary
      const statuses = rootState['dictionaries'].paymentStatusesDictionary
      return state.payments.map((payment) => {
        const mappedPayment = {
          ...payment,
          payment_type_name: types.find((type) => type.value === payment.type)?.name || payment.type,
          payment_status_name: statuses.find((status) => status.value === payment.payment_status)?.name || payment.payment_status,
        }
        if (payment.device) {
          mappedPayment.device = {
            ...payment.device,
            name: machines.find((machine) => machine.value === payment.device.type)?.name || payment.device.type,
          }
        }
        return mappedPayment
      })
    },
    getSelectedPaymentWithDictionaries(state, getters, rootState) {
      const machines = rootState['dictionaries'].machinesTypesDictionary
      const types = rootState['dictionaries'].paymentTypesDictionary
      const statuses = rootState['dictionaries'].paymentStatusesDictionary
      if (Object.entries(state.payment).length) {
        return {
          ...state.payment,
          device: state.payment.device
            ? {
                ...state.payment.device,
                name: machines.find((machine) => machine.value === state.payment.device.type)?.name || state.payment.device.type,
              }
            : null,
          payment_type_name: types.find((type) => type.value === state.payment.type)?.name || state.payment.type,
          payment_status_name:
            statuses.find((status) => status.value === state.payment.payment_status)?.name || state.payment.payment_status,
        }
      } else return {}
    },
    getModifiedStatisticChartDataPayments(state) {
      const modifiedData = { ...state.statisticChartDataPayments, datasets: [] }
      modifiedData.datasets = state.statisticChartDataPayments.datasets.map((item) => {
        return { ...item, data: [...item.amount], originData: [...item.data] } // Shallow copy of item and deep copy of data
      })
      return modifiedData
    },
  },
}
