<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>{{ company.name }}</v-toolbar-title>
        <v-chip v-if="getStatus" class="ml-3" :text-color="getStatus.color" small>
          <v-avatar left>
            <v-icon :color="getStatus.color" size="20">{{ getStatus.icon }}</v-icon>
          </v-avatar>
          <span class="text-caption">{{ getStatus.title }}</span>
        </v-chip>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">Картка компанії</div>

        <template v-slot:extension>
          <v-tabs v-model="currentTab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="(item, index) in tabs" :key="index" @click="editCard = false">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <v-card flat color="blue-grey lighten-5">
            <component
              v-if="isCompanyExist && isComponentRerendered && isLoaded"
              :is="item.component"
              :currentItem="isCurrentCompanyCard ? currentCompany : company"
              :isCurrentCompanyCard="isCurrentCompanyCard"
              :isDataSaved="isDataSaved"
              :currentTabName="currentTabName"
              :editCard="editCard"
              :hasEditPermission="hasEditPermission"
              @saveData="saveData"
              @changes="editCard = true"
            ></component>
            <v-container v-show="showEditButtons && hasEditPermission" fluid>
              <div class="d-flex justify-end">
                <v-btn v-if="!editCard && !isContactsTabActive" @click="editCard = true" class="mr-2" small>Редагувати</v-btn>
                <v-btn v-if="editCard" @click="editCard = false" class="mr-2" small>
                  {{ isContactsTabActive ? 'Скасувати' : 'Повернутись' }}
                </v-btn>
                <v-btn v-show="editCard" @click="showModal = true" color="primary" small> Зберегти </v-btn>
              </div>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="isDataSaved = true" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
// import { COMPANY_SCHEMA } from '@/const/apiSchemas'
// import { mergeObjectsDeep } from '@/helpers/mergeObjects'
import ConvertStatusesTypes from '@/mixins/convertStatusesTypes'
import forceRerender from '@/mixins/forceRerender'
import BackButton from '@/components/common/BackButton.vue'

export default {
  name: 'CompanyCard',

  components: {
    BackButton,
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
    Info: () => import('@/components/administration/company/Info'),
    CardContacts: () => import('@/components/partials/contacts/CardContacts'),
    Subscription: () => import('@/components/administration/company/Subscription'),
    PaymentSystem: () => import('@/components/administration/company/PaymentSystem'),
  },

  mixins: [forceRerender, ConvertStatusesTypes],

  data() {
    return {
      showModal: false,
      currentTab: 0,
      isDataSaved: false,
      editCard: false,
      isLoaded: false,
      tabs: [
        {
          name: 'info',
          title: 'Загальна інфо',
          component: 'Info',
        },
        {
          name: 'paymentSystem',
          title: 'Платіжна система',
          component: 'PaymentSystem',
        },
        {
          name: 'subscription',
          title: 'Підписки',
          component: 'Subscription',
        },
        {
          name: 'contacts',
          title: 'Контакти',
          component: 'CardContacts',
        },
      ],
    }
  },

  computed: {
    ...mapState('companies', ['currentCompany', 'company']),
    ...mapState('dictionaries', ['technicalContactTypesDictionary', 'personalContactTypesDictionary', 'rolesDictionary']),
    ...mapState('profile', ['userRoles']),

    currentCompanyId() {
      return this.$route.params.id
    },
    isCompanyExist() {
      return Object.entries(this.company).length
    },
    isCurrentCompanyCard() {
      return this.currentCompany.id === this.company.id
    },
    showEditButtons() {
      return this.currentTabName !== 'subscription'
    },
    isContactsTabActive() {
      return this.currentTabName === 'contacts'
    },
    currentTabName() {
      return this.tabs[this.currentTab].name
    },
    hasEditPermission() {
      return (
        this.userRoles.includes(this.rolesDictionary.ADMIN) ||
        this.userRoles.includes(this.rolesDictionary.OWNER) ||
        (this.userRoles.includes(this.rolesDictionary.SUPPORT) &&
          (this.currentTabName === 'info' || this.currentTabName === 'contacts'))
      )
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.SET_ADDRESS({})
  },

  methods: {
    ...mapActions('companies', ['loadCurrentCompany', 'getSelectedCompany', 'updateSelectedCompany']),
    ...mapActions('addresses', ['loadAddress']),
    ...mapActions('logs', ['displayWarningAlert']),
    ...mapMutations('addresses', ['SET_ADDRESS']),

    async initialize() {
      this.isLoaded = false
      if (this.currentCompanyId !== 'undefined') {
        await this.getSelectedCompany(this.currentCompanyId)
        if (this.company.address) {
          const addressPayload = {
            companyId: this.currentCompanyId,
            addressId: this.company.address.id,
            type: 'company',
          }
          await this.loadAddress(addressPayload)
        }
        this.isLoaded = true
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
    async saveData(updatedCompany) {
      // updatedCompany = mergeObjectsDeep(COMPANY_SCHEMA, updatedCompany)
      delete updatedCompany.id
      delete updatedCompany.logo
      // delete updatedCompany.address.id
      delete updatedCompany.address
      // delete updatedCompany.owner.id
      const payload = {
        updatedCompany,
        id: this.currentCompanyId,
      }
      this.showModal = false
      await this.updateSelectedCompany(payload)
      // await this.getSelectedCompany(this.currentCompanyId)
      // await this.getAllCompanies()
      await this.forceRerender()
      this.isDataSaved = false
      this.editCard = false
    },
  },
}
</script>

<style scoped></style>
