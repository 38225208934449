<template>
  <v-container fluid>
    <div v-if="points && points.length" class="mt-6">
      <div v-for="(servicePoint, index) in points" :key="servicePoint.id" class="d-flex mb-3">
        <div class="mr-6">
          <span class="text-caption mr-1">{{ index + 1 }}.</span>
          <span class="font-weight-medium ml-1">ID{{ servicePoint.id }}</span>
        </div>
        <div>
          <span>Адреса:</span>
          <router-link
            v-if="servicePoint.address"
            :to="{ name: 'ServicePointCard', params: { id: currentCompanyId, servicePointId: servicePoint.id } }"
            class="text-decoration-none font-weight-medium"
          >
            {{ servicePoint.address.country }}, {{ servicePoint.address.city }}, {{ servicePoint.address.street }},
            {{ servicePoint.address.building_number }}
          </router-link>
        </div>
      </div>
    </div>
    <div v-else class="mt-6">
      <v-alert dense border="left" text type="warning" width="max-content"> Торгівельні точки не підключено </v-alert>
    </div>
  </v-container>
</template>

<script>
import { ROUTER_LINKS } from '@/const/router-links.enum'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PartnerPoints',

  props: {
    currentItem: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
  }),

  computed: {
    ...mapState('partners', ['partner', 'servicePoints']),
    ...mapState('companies', ['company']),
    currentCompanyId() {
      return this.$route.params.id
    },
    points() {
      return this.servicePoints?.points || null
    },
  },

  async created() {
    await this.initialize()
  },

  beforeDestroy() {},

  methods: {
    ...mapActions('partners', ['loadSelectedPartnersServicePoints']),

    async initialize() {
      if (!this.servicePoints || this.currentItem.id !== this.servicePoints.partnerId) {
        const payload = { companyId: this.currentCompanyId, partnerId: this.currentItem.id }
        await this.loadSelectedPartnersServicePoints(payload)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
